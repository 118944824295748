<template>
  <h2 class="title"><slot></slot></h2>
</template>

<style scoped lang="scss">
h2.title {
  font-size: 2.5em;
  font-family: font(black);
  font-weight: 400;
  line-height: 1em;
  margin-bottom: 11px;
  margin-top: 60px;
  text-transform: uppercase;
  -webkit-text-stroke: 1.5px color(black);
  @include media(">=desktop") {
    display: none;
  }
  &--yellow {
    color: color(yellow);
  }
  &--pink {
    color: color(pink);
  }
}
</style>
