<template>
  <div class="marquee-bar">
    <Vue3Marquee :clone="true" :duration="speed">
      <div
        class="marquee-item"
        v-for="(content, index) in content"
        :key="index"
      >
        <p v-if="content.type === 'text'">{{ content.value }}</p>
        <img
          :src="require(`@/assets/img/bar/${content.value}`)"
          alt=""
          v-if="content.type === 'image'"
        />
      </div>
    </Vue3Marquee>
  </div>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";
export default {
  props: {
    content: {
      type: Array,
      required: true,
    },
    speed: {
      type: Number,
      default: 10,
    },
  },
  components: {
    Vue3Marquee,
  },
};
</script>

<style lang="scss">
body:not(.section-0) {
  .marquee {
    .marquee-bar {
      &:nth-child(1) {
        opacity: 0;
      }
      &:nth-child(2) {
        opacity: 1;
      }
    }
  }
}
body.section-0 {
  .marquee {
    .marquee-bar {
      &:nth-child(1) {
        opacity: 1;
      }
      &:nth-child(2) {
        opacity: 0;
      }
    }
  }
}
.marquee {
  .marquee-bar {
    &:nth-child(1) {
      .marquee-bar__content {
        animation-duration: 30s;
      }
    }
    &:nth-child(2) {
      .marquee-bar__content {
        animation-duration: 800s;
      }
    }
  }
}

.marquee-item {
  display: inline-flex;
  align-items: center;
  padding-right: 6px;
  flex-shrink: 0;
}

.marquee-bar {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 0 2px;
  background-color: color(purple2);
  width: 100%;
  z-index: 99;
  white-space: nowrap;
  overflow: hidden;
  @include media(">=tablet") {
    padding: 8px 0 2px;
  }
  p {
    font-size: 1.313rem;
    line-height: 1em;
    display: flex;
    gap: 6px;
    align-items: center;
    color: color(white);
    font-family: font(dirty);
    text-transform: lowercase;
    margin-bottom: initial;
    -webkit-text-stroke: 1px color(black);
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    @include media(">=tablet") {
      font-size: 1.875rem;
    }
  }
  img {
    transform: translateY(-3px);
    height: 24px;
    width: auto;
    @include media("<tablet") {
      height: 18px;
    }
  }
}
.section-0,
.section-5 {
  .marquee-bar {
    background-color: color(purple2);
    p {
      color: color(white);
    }
  }
}
.section-1 {
  .marquee-bar {
    background-color: color(red);
    p {
      color: color(white);
    }
  }
}
.section-2 {
  .marquee-bar {
    background-color: color(yellow);
    p {
      color: color(pink);
    }
  }
}
.section-3 {
  .marquee-bar {
    background-color: color(blue);
    p {
      color: color(pink);
    }
  }
}
.section-4 {
  .marquee-bar {
    background-color: color(blue);
    p {
      color: color(red);
    }
  }
}

.has-high-contrast {
  .marquee-bar {
    background-color: color(black);
    p {
      color: color(white);
    }
  }
  &.section-0,
  &.section-5 {
    .marquee-bar {
      background-color: color(white);
      p {
        color: color(black);
      }
    }
  }
}
</style>
