<template>
  <section :class="['accessibility-bar', { open: active }]">
    <button class="accessibility-bar__button" @click.stop="active = !active">
      <span>
        <img src="@/assets/img/acessibilidade.svg" />
        <img src="@/assets/img/close2.svg" />
      </span>
    </button>
    <div class="accessibility-bar__content">
      <div class="accessibility-bar__content__wrapper">
        <p>Tamanho da letra:</p>
        <ul>
          <li><button @click="resize('+')">A+</button></li>
          <li><button @click="resize('-')">A-</button></li>
        </ul>
      </div>
      <div class="accessibility-bar__content__wrapper">
        <p>Contraste</p>
        <ul>
          <li :class="{ active: !classContrast }">
            <button @click="contrast('claro')">Claro</button>
          </li>
          <li :class="{ active: classContrast }">
            <button @click="contrast('escuro')">Escuro</button>
          </li>
        </ul>
      </div>
      <div class="accessibility-bar__content__wrapper">
        <a @click.prevent.stop="reset">Reiniciar configurações</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      classContrast: false,
      active: false,
      fontSizeMultiplier: 1,
      accessibility: false,
    };
  },
  computed: {
    computedFontSize() {
      const breakpoints = {
        desktop: 20,
        large: 21.2,
        wxga: 22.6,
        exLarge: 24.6,
        hd: 25.6,
        fhd: 31,
      };

      let baseFontSize = 16;

      if (window.innerWidth >= 1920) {
        baseFontSize = breakpoints.fhd;
      } else if (window.innerWidth >= 1600) {
        baseFontSize = breakpoints.hd;
      } else if (window.innerWidth >= 1536) {
        baseFontSize = breakpoints.exLarge;
      } else if (window.innerWidth >= 1440) {
        baseFontSize = breakpoints.wxga;
      } else if (window.innerWidth >= 1366) {
        baseFontSize = breakpoints.large;
      } else if (window.innerWidth >= 1280) {
        baseFontSize = breakpoints.desktop;
      }

      return `${baseFontSize * this.fontSizeMultiplier}px`;
    },
  },
  methods: {
    resize(value) {
      if (value == "+") {
        this.fontSizeMultiplier += 0.1;
      } else {
        this.fontSizeMultiplier -= 0.1;
      }
      this.updateBodyFontSize();
      document.body.classList.add("accessibility");
      if (!this.accessibility) {
        this.$emit(
          "showMessage",
          "Visualização da página em modo de acessibilidade."
        );
      }
      this.accessibility = true;
    },
    contrast(value) {
      document.body.classList.add("accessibility");
      if (value == "escuro") {
        document.body.classList.add("has-high-contrast");
        this.classContrast = true;
      } else {
        document.body.classList.remove("has-high-contrast");
        this.classContrast = false;
      }
      if (!this.accessibility) {
        this.$emit(
          "showMessage",
          "Visualização da página em modo de acessibilidade."
        );
      }
      this.accessibility = true;
    },
    reset() {
      this.fontSizeMultiplier = 1;
      this.updateBodyFontSize();
      document.body.classList.remove("accessibility");
      document.body.classList.remove("has-high-contrast");
      this.classContrast = false;
      if (this.accessibility) {
        this.$emit("showMessage", "Modo de acessibilidade desativado.");
      }
      this.accessibility = false;
      this.active = false;
    },
    updateBodyFontSize() {
      document.body.style.fontSize = this.computedFontSize;
    },
  },
  mounted() {
    this.updateBodyFontSize();
    window.addEventListener("resize", this.updateBodyFontSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateBodyFontSize);
  },
};
</script>

<style lang="scss">
.accessibility-bar {
  position: fixed;
  right: 10px;
  bottom: 0;
  transform: translate(0, calc(100% - 54px));
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  z-index: 99;
  transition: 0.3s ease-in-out;
  @include media(">=tablet") {
    top: 440px;
    right: 0;
    bottom: initial;
    flex-direction: initial;
    align-items: center;
    transform: translate(calc(100% - 54px), -50%);
  }
  &__button {
    width: 47px;
    height: 54px;
    border: 2px solid color(black);
    border-right: initial;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(white);
    @include media(">=tablet") {
      width: 54px;
      height: 47px;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      border-top-right-radius: initial;
    }
    span {
      width: 30px;
      height: 30px;
      background: color(black);
      position: relative;
      border-radius: 50%;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s ease-in-out;
        &:nth-child(2) {
          opacity: 0;
        }
      }
    }
  }
  &__content {
    background: color(white);
    padding: 35px 22px;
    text-align: center;
    border: 2px solid color(black);
    &__wrapper {
      text-align: center;
      p {
        font-size: 16px;
        margin-bottom: 11px;
        font-weight: 500;
      }
      ul {
        list-style: none;
        display: flex;
        justify-content: center;
        gap: 18px;
        li {
          button {
            border: 1px solid color(gray);
            font-size: 18px;
            font-weight: 500;
            padding: 0 10px;
            border-radius: 5px;
            min-width: 40px;
            transition: 0.3s ease-in-out;
            cursor: pointer;
            @include media(">=tablet") {
              &:hover,
              &:focus {
                background: color(gray);
              }
            }
          }
          &.active {
            button {
              color: color(white);
              background: color(black);
            }
          }
        }
      }
      &:nth-child(2) {
        margin-top: 14px;
        ul {
          gap: 8px;
          li {
            button {
              padding: 0 20px;
              min-width: 70px;
            }
          }
        }
      }
      a {
        text-align: color(black);
        text-decoration: underline;
        font-size: 14px;
        font-weight: 500;
        display: block;
        text-align: center;
        margin-top: 24px;
        cursor: pointer;
      }
    }
  }
  &.open {
    transform: translate(0, 0);
    @include media(">=tablet") {
      transform: translate(0, -50%);
    }
    .accessibility-bar {
      &__button {
        span {
          img {
            &:nth-child(1) {
              opacity: 0;
            }
            &:nth-child(2) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
