<template>
  <header class="header" ref="header">
    <div class="container">
      <div class="header__wrapper">
        <div class="header__logos">
          <div class="logo-premio__wrapper">
            <img
              src="@/assets/img/logo-premio-roxo.svg"
              alt=""
              class="logo-premio logo-premio--roxo"
            />
            <img
              src="@/assets/img/logo-premio-verde.svg"
              alt=""
              class="logo-premio logo-premio--verde"
            />
            <img
              src="@/assets/img/logo-premio-vermelho.svg"
              alt=""
              class="logo-premio logo-premio--vermelho"
            />
          </div>
          <figure class="logo-escola__wrapper">
            <img
              src="@/assets/img/logo-escola-branco.svg"
              alt=""
              class="logo-escola logo-escola--branco"
            />
            <img
              src="@/assets/img/logo-escola-claro.svg"
              alt=""
              class="logo-escola logo-escola--claro"
            />
            <img
              src="@/assets/img/logo-escola-verde.svg"
              alt=""
              class="logo-escola logo-escola--verde"
            />
          </figure>
        </div>
        <div class="header__nav">
          <a
            href="https://inscricoes.criativosdaescola.com.br/"
            target="_blank"
            class="btn btn--pink subscription"
          >
            <span> inscreva-se </span>
          </a>
          <div class="header__menu">
            <div
              :class="['btn header__menu__btn', { active: menu }]"
              @click="menu = !menu"
            >
              <span>
                <button>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </button>
              </span>
            </div>
            <div class="header__menu__label">
              <span :class="animationClass"></span>
            </div>
            <nav :class="{ active: menu }">
              <ul>
                <li @click="navigateToSection('hero')"><span>Home</span></li>
                <li @click="navigateToSection('about')">
                  <span>Sobre o Prêmio</span>
                </li>
                <li @click="navigateToSection('participate')">
                  <span>Como Participar</span>
                </li>
                <li @click="navigateToSection('instagram')">
                  <span>Para Inspirar</span>
                </li>
                <li @click="navigateToSection('faq')">
                  <span>Perguntas e respostas</span>
                </li>
                <li @click="navigateToSection('contact')">
                  <span>Fale com a gente</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      lastScrollTop: 0,
      animationState: "enter",
      menu: false,
    };
  },
  computed: {
    animationClass() {
      return this.animationState === "exit" ? "animated-exit" : "animated";
    },
  },
  methods: {
    navigateToSection(sectionRef) {
      this.menu = false;
      this.$emit("scroll-to-section", sectionRef);
    },
    handleScroll() {
      const currentScrollTop = window.scrollY;
      const isMobile = window.innerWidth <= 768;
      const header = this.$refs.header;
      if (
        isMobile &&
        currentScrollTop > header.getBoundingClientRect().height + 60
      ) {
        if (currentScrollTop > this.lastScrollTop) {
          header.classList.add("header--outview");
          header.classList.remove("header--inview");
        } else {
          header.classList.remove("header--outview");
          header.classList.add("header--inview");
          header.classList.add("header--fixed");
        }
      } else {
        header.classList.remove("header--outview");
        header.classList.remove("header--inview");
        header.classList.remove("header--fixed");
      }
      setTimeout(() => {
        this.lastScrollTop = currentScrollTop;
      }, 500);
    },
    startExitAnimation() {
      const bodyClass = document.body.className;
      this.animationState = "exit";
      if (!bodyClass.includes("section-0")) {
        setTimeout(() => {
          this.restartAnimation();
        }, 100);
      }
    },
    restartAnimation() {
      this.animationState = "enter";
    },
  },
  mounted() {
    /*const observer = new MutationObserver(() => {
      this.startExitAnimation();
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });*/
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.header {
  position: absolute;
  top: 49px;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: 0.3s ease-in-out;
  @include media(">=tablet") {
    position: fixed;
    top: 72px;
  }
  &--fixed {
    @include media("<tablet") {
      position: fixed;
    }
  }
  &--inview {
    @include media("<tablet") {
      transform: translateY(0);
      margin-top: initial;
    }
  }
  &--outview {
    @include media("<tablet") {
      transform: translateY(calc(-100% - 80px));
      z-index: -1;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logos {
    display: flex;
    align-items: center;
    .logo-premio {
      max-width: 100%;
      transition: 0.3s ease-in-out;
      top: 0;
      &__wrapper {
        width: 130px;
        position: relative;
        @include media(">=tablet") {
          width: 226px;
        }
      }
    }
    .logo-escola {
      max-width: 100%;
      transition: 0.3s ease-in-out;
      top: 0;
      &__wrapper {
        width: 130px;
        position: relative;
        @include media(">=tablet") {
          width: 226px;
        }
      }
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    gap: 33px;
    .subscription {
      @include media("<tablet") {
        display: none;
      }
      span {
        padding-left: 45px;
        padding-right: 45px;
        @include media(">=tablet") {
          font-size: 32px;
        }
      }
    }
  }
  &__menu {
    position: relative;
    transform: translateY(-8px);
    @include media(">=tablet") {
      transform: initial;
    }
    &__btn {
      cursor: pointer;
      span {
        padding: 0;
      }
      button {
        appearance: none;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        border: 0px none;
        flex-direction: column;
        background-color: color(white);
        appearance: none;
        @include media(">=tablet") {
          width: 60px;
          height: 60px;
        }
        .bar {
          width: 25px;
          height: 3px;
          background-color: color(black);
          transition: 0.3s ease-in-out;
          position: absolute;
          left: 0;
          transform: translateX(50%);
          @include media(">=tablet") {
            width: 32px;
            height: 4px;
          }
          &:nth-child(1) {
            top: 17px;
            @include media(">=tablet") {
              top: 21px;
            }
          }
          &:nth-child(2) {
            top: 30px;
            @include media(">=tablet") {
              top: 38px;
            }
          }
        }
      }
      &.active {
        span {
          transform: translateX(10px) translateY(10px);
        }
        button {
          .bar {
            &:nth-child(1) {
              transform: rotate(45deg) translate(-50%, -50%);
              top: calc(50% + 8px);
              left: calc(50% - 4px);
              @include media(">=tablet") {
                top: calc(50% + 10px);
                left: calc(50% - 7px);
              }
            }
            &:nth-child(2) {
              transform: rotate(-45deg) translate(-50%, -50%);
              top: calc(50% - 10px);
              left: calc(50% - 1px);
              @include media(">=tablet") {
                top: calc(50% - 14px);
                left: calc(50% - 4px);
              }
            }
          }
        }
        &::before {
          height: calc(100% - 10px);
          top: 15px;
        }
        &::after {
          width: calc(100% - 10px);
          left: 15px;
        }
      }
    }
    &__label {
      position: absolute;
      top: -36px;
      right: -61px;
      height: 0;
      transform: rotate(-90deg) translate(-100%, 100%);
      transition: opacity 0.3s ease-in-out;
      @include media("<tablet") {
        display: none;
      }
      span {
        border-bottom: 2px solid color(black);
        border-left: 2px solid color(black);
        padding-left: 10px;
        padding-right: 19px;
        display: block;
        transform-origin: right;
        &::before {
          content: "";
          text-transform: uppercase;
          font-weight: 900;
          font-size: 1.25rem;
          white-space: nowrap;
          display: block;
        }
      }
    }
    nav {
      position: absolute;
      top: 65px;
      right: -10px;
      width: 0;
      @include media(">=tablet") {
        top: 82px;
        right: -12px;
      }
      ul {
        list-style: none;
        li {
          transform: scaleX(0);
          transform-origin: right;
          transition: 0.3s ease-in-out;
          width: 300px;
          position: relative;
          &::after,
          &::before {
            content: "";
            background-color: color(black);
            position: absolute;
            z-index: -1;
            transition: 0.3s ease-in-out;
          }
          &::before {
            width: 8px;
            height: calc(100% - 8px);
            bottom: 4px;
            right: 0;
            transform: skewY(45deg);
          }
          &::after {
            width: calc(100% - 9px);
            height: 8px;
            bottom: 0px;
            right: 4px;
            transform: skewX(45deg);
          }
          span {
            background-color: color(white);
            font-family: font(dirty);
            font-size: 1.5rem;
            text-transform: lowercase;
            width: 100%;
            padding: 9px 14px 3px;
            border: 2px solid color(black);
            cursor: pointer;
            transition: 0.3s ease-in-out;
            white-space: nowrap;
            display: block;
          }
          &:not(:last-child) {
            margin-bottom: 3px;
          }
          &:hover,
          &:focus {
            span {
              transform: translate(-8px, -8px);
            }

            &::before {
              height: 100%;
            }
            &::after {
              width: calc(100% + 1px);
            }
          }
          &:nth-child(1) {
            &:hover,
            &:focus {
              span {
                background-color: color(pink2);
              }
            }
          }
          &:nth-child(2) {
            &:hover,
            &:focus {
              span {
                background-color: color(red);
              }
            }
          }
          &:nth-child(3) {
            &:hover,
            &:focus {
              span {
                background-color: color(blue);
              }
            }
          }
          &:nth-child(4) {
            &:hover,
            &:focus {
              span {
                background-color: color(green);
              }
            }
          }
          &:nth-child(5) {
            &:hover,
            &:focus {
              span {
                background-color: color(purple);
              }
            }
          }
          &:nth-child(6) {
            &:hover,
            &:focus {
              span {
                background-color: color(pink);
              }
            }
          }
        }
      }
      &.active {
        width: initial;
        ul {
          li {
            transform: scaleX(1);
            &:nth-child(2) {
              transition-delay: 0.1s;
            }
            &:nth-child(3) {
              transition-delay: 0.2s;
            }
            &:nth-child(4) {
              transition-delay: 0.3s;
            }
            &:nth-child(5) {
              transition-delay: 0.4s;
            }
            &:nth-child(6) {
              transition-delay: 0.5s;
            }
          }
        }
      }
    }
  }
}
.section-0,
.section-1 {
  .header {
    &__nav {
      .subscription {
        opacity: 0;
        z-index: -1;
      }
    }
  }
}
.section-0,
.section-5 {
  .header {
    &__menu {
      ul {
        li {
          &::after,
          &::before {
            background-color: color(yellow);
          }
        }
      }
    }
  }
  .logo-escola {
    &:not(.logo-escola--claro) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--claro {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .logo-premio {
    &:not(.logo-premio--verde) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--verde {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
}

.section-1 {
  .logo-escola {
    &:not(.logo-escola--verde) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--verde {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .logo-premio {
    &:not(.logo-premio--roxo) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--roxo {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .header {
    &__menu {
      &__label {
        top: -18px;
        right: -69px;
        span {
          &::before {
            content: "sobre o prêmio";
          }
        }
      }
    }
  }
}
.section-2 {
  .logo-escola {
    &:not(.logo-escola--branco) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--branco {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .logo-premio {
    &:not(.logo-premio--roxo) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--roxo {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .header {
    &__menu {
      &__label {
        top: -21px;
        right: -76px;
        span {
          &::before {
            content: "como participar";
          }
        }
      }
    }
  }
}
.section-3 {
  .logo-escola {
    &:not(.logo-escola--claro) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--claro {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .logo-premio {
    &:not(.logo-premio--vermelho) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--vermelho {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .header {
    &__menu {
      &__label {
        top: -7px;
        right: -61px;
        span {
          &::before {
            content: "para inspirar";
          }
        }
      }
    }
  }
}
.section-4 {
  .logo-escola {
    &:not(.logo-escola--branco) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--branco {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .logo-premio {
    &:not(.logo-premio--vermelho) {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
    &--vermelho {
      opacity: 1;
      position: relative;
      z-index: 2;
    }
  }
  .header {
    &__menu {
      &__label {
        top: -55px;
        right: -108px;
        span {
          &::before {
            content: "perguntas e respostas";
          }
        }
      }
    }
    &__nav {
      .subscription {
        span {
          background-color: color(red);
        }
      }
    }
  }
}
.section-5 {
  .header {
    &__menu {
      &__label {
        top: -24px;
        right: -78px;
        span {
          border-color: color(yellow);
          &::before {
            content: "fale com a gente";
            color: color(white);
          }
        }
      }
    }
  }
}
.section-0,
.section-5 {
  .header {
    &__nav {
      .subscription,
      .header__menu__btn {
        &::after,
        &::before {
          background-color: color(yellow);
        }
      }
    }
  }
}

.animated {
  animation: titleIn 0.3s normal forwards;
}

.animated-exit {
  animation: titleOut 0.3s normal forwards;
}

@keyframes titleIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes titleOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
