<template>
  <div v-if="visible" class="notification">
    {{ message }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: "",
    };
  },
  methods: {
    showMessage(message) {
      this.message = message;
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  position: fixed;
  bottom: 24px;
  left: 50%;
  background-color: color(white);
  color: color(black);
  padding: 10px 20px 13px;
  border: 1.6px solid color(black);
  z-index: 1000;
  transform: translateX(-50%);
}
</style>
