<template>
  <section :class="['faq-item', { active: isActive }]">
    <button class="faq-item__header" @click="$emit('toggle', item)">
      <h3>
        <span>{{ item }}.</span> {{ title }}
      </h3>
      <img src="@/assets/img/arrow-faq.svg" alt="" />
    </button>
    <div class="faq-item__body" v-html="content"></div>
  </section>
</template>

<script>
export default {
  props: {
    item: Number,
    title: String,
    content: String,
    isActive: Boolean,
  },
};
</script>

<style lang="scss">
.faq-item {
  margin-bottom: 10px;
  background-color: color(blue);
  border: 2px solid color(black);
  transition: 0.3s ease-in-out;
  cursor: pointer;
  @include media(">=desktop") {
    margin-bottom: 7px;
  }
  &:not(.active) {
    &:hover,
    &:focus {
      @include media(">=desktop") {
        background-color: color(blue2);
        .faq-item__header {
          h3 {
            span {
              color: color(red);
            }
          }
          img {
            transform: translateX(0px);
          }
        }
      }
    }
  }
  &__header {
    width: 100%;
    font-size: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
    gap: 20px;
    text-align: left;
    @include media(">=desktop") {
      padding: 38px 35px;
    }
    h3 {
      font-size: 1.563em;
      font-weight: 400;
      line-height: 1.2em;
      font-family: font(black);
      text-transform: uppercase;
      @include media(">=desktop") {
        display: flex;
        align-items: center;
        font-size: 1.8em;
        line-height: 1em;
      }
      span {
        font-size: 1em;
        color: color(white);
        -webkit-text-stroke: 1.5px color(black);
        font-weight: 400;
        transition: 0.3s ease-in-out;
        @include media(">=desktop") {
          width: 7vw;
          flex: 1 0 7vw;
          font-size: 2.138em;
        }
      }
    }
    img {
      transform: translateX(-10px);
      transition: 0.3s ease-in-out;
      @include media("<desktop") {
        display: none;
      }
    }
  }
  &__body {
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    p,
    ul {
      font-size: 1.313em;
      line-height: 1.2em;
      padding: 0 20px;
      margin: 0 0 26px;
      @include media(">=desktop") {
        margin: 0px 0px 40px 10%;
        font-size: 1.1em;
        max-width: 68%;
      }
    }
    ul {
      list-style: none;
    }
    a {
      color: color(purple);
      text-decoration: underline;
    }
  }
  &.active {
    background-color: color(white);
    .faq-item__header {
      h3 span {
        color: color(red);
      }
      img {
        opacity: 0;
      }
    }
    .faq-item__body {
      max-height: 1000px;
      // padding-bottom: 32px;
      @include media(">=desktop") {
        padding-bottom: initial;
      }
    }
  }
}
</style>
