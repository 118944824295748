<template>
  <div class="marquee">
    <MarqueeBar
      v-for="(item, index) in marquee"
      :key="index"
      :content="item.content"
      :speed="item.speed"
    ></MarqueeBar>
  </div>
  <TheHeader ref="headerComponent" @scroll-to-section="handleScrollToSection" />
  <AccessibilityBar @showMessage="displayMessage" />
  <AccessibilityMessage ref="accessibilityMessage" />
  <router-view
    :scrollToSection="scrollToSection"
    :headerComponent="headerComponent"
  />
</template>

<script>
import AccessibilityBar from "@/components/AccessibilityBar.vue";
import AccessibilityMessage from "@/components/AccessibilityMessage.vue";
import MarqueeBar from "@/components/MarqueeBar.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  components: {
    AccessibilityBar,
    AccessibilityMessage,
    MarqueeBar,
    TheHeader,
  },
  data() {
    return {
      scrollToSection: null,
      headerComponent: null,
      marquee: [
        {
          speed: 12,
          content: [
            {
              type: "text",
              value: "mais gente para cuidar da natureza",
            },
            {
              type: "image",
              value: "icon1.svg",
            },
            {
              type: "text",
              value: "mais natureza para cuidar da gente",
            },
            {
              type: "image",
              value: "icon2.svg",
            },
          ],
        },
        {
          speed: 40,
          content: [
            {
              type: "text",
              value: "ideias para um mundo mais verde",
            },
            {
              type: "image",
              value: "icon1.svg",
            },
            {
              type: "text",
              value: "uma escola de cada vez",
            },
            {
              type: "image",
              value: "icon2.svg",
            },
            {
              type: "text",
              value: "ideias para um mundo mais verde",
            },
            {
              type: "image",
              value: "icon3.svg",
            },
            {
              type: "text",
              value: "uma escola de cada vez",
            },
            {
              type: "image",
              value: "icon4.svg",
            },
            {
              type: "text",
              value: "ideias para um mundo mais verde",
            },
            {
              type: "image",
              value: "icon5.svg",
            },
            {
              type: "text",
              value: "uma escola de cada vez",
            },
            {
              type: "image",
              value: "icon6.svg",
            },
            {
              type: "text",
              value: "ideias para um mundo mais verde",
            },
            {
              type: "image",
              value: "icon7.svg",
            },
            {
              type: "text",
              value: "uma escola de cada vez",
            },
            {
              type: "image",
              value: "icon8.svg",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleScrollToSection(sectionRef) {
      this.scrollToSection = sectionRef;
    },
    displayMessage(message) {
      this.$refs.accessibilityMessage.showMessage(message);
    },
  },
  mounted() {
    this.headerComponent = this.$refs.headerComponent;
  },
};
</script>

<style lang="scss">
@import "scss/style";
.no-scroll {
  overflow: hidden !important;
}
</style>
