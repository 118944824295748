<template>
  <div class="scroll-container" ref="scrollContainer">
    <section class="page hero" ref="hero">
      <div class="container">
        <div class="hero__wrapper">
          <div class="hero__legend">
            <p>
              <img src="@/assets/img/arrow-yellow.svg" alt="" />
              <span>deslize para navegar</span>
            </p>
          </div>
          <div class="hero__image">
            <Vue3Lottie
              :animationData="anima.destaque"
              class="feature-mobile hide-on-mobile"
            />
            <Vue3Lottie
              :animationData="anima.destaqueM"
              class="feature-mobile hide-on-desktop"
            />
          </div>
          <div class="hero__content">
            <div class="hero__content__text">
              <img
                src="@/assets/img/engrenagem.svg"
                alt=""
                class="hide-on-desktop"
                ref="icon"
              />
              <p>
                <span>Chame os amigos</span> e criem juntos um projeto capaz de
                <strong>transformar a natureza ao seu redor.</strong>
              </p>
              <div class="hero__content__text__half hide-on-mobile">
                <div class="botao">
                  <a
                    href="https://inscricoes.criativosdaescola.com.br/"
                    target="_blank"
                    class="btn btn--blue-2"
                  >
                    <span> inscreva-se </span>
                  </a>
                </div>
                <div>
                  <p>Envie seu projeto até</p>
                  <h3>03dez</h3>
                </div>
              </div>
            </div>
            <div class="hero__content__info">
              <div class="hero__content__info__left">
                <p>
                  <Vue3Lottie :animationData="anima.exclamacao" />
                  <span>Os grupos premiados ganham</span>
                </p>
                <h3>12 mil reais</h3>
              </div>
              <div class="hero__content__info__right">
                e ainda participam da <strong>COP 30</strong>, a conferência
                mais importante do mundo sobre o clima.
              </div>
            </div>

            <div class="hero__content__text__half hide-on-desktop">
              <a
                href="https://inscricoes.criativosdaescola.com.br/"
                target="_blank"
                class="btn btn--blue-2"
              >
                <span> inscreva-se </span>
              </a>
              <div>
                <p>Envie seu projeto até</p>
                <h3>03dez</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page about" ref="about">
      <div class="container">
        <TheTitle class="title--yellow">Sobre o prêmio</TheTitle>
        <div class="about__wrapper">
          <div class="about__left">
            <div class="about__left__wrapper">
              <h2>
                O Prêmio Criativos chega à sua 8ª edição de cara nova e traz a
                natureza para o centro da conversa e da criatividade.
              </h2>
              <p>
                Neste ano, queremos inspirar a criação de soluções práticas e
                efetivas dentro e fora da escola florescendo na natureza. Nossa
                missão é reconhecer o protagonismo estudantil na transformação
                da realidade.
              </p>
              <div>
                <Vue3Lottie :animationData="anima.galhos" class="galhos" />
                <h3>Monte seu grupo <br />de trabalho e participe!</h3>
              </div>
            </div>
          </div>
          <div class="about__right">
            <button
              class="about__right__content"
              tabindex="0"
              @click="modal = !modal"
            >
              <figure>
                <img src="@/assets/img/curiosidade.svg" alt="" />
                <img src="@/assets/img/curiosidade-bg.svg" alt="" />
              </figure>

              <svg
                width="65px"
                height="58px"
                viewBox="0 0 65 58"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="arrow"
              >
                <g
                  id="Desktop"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="about-arrow"
                    transform="translate(-863.000000, -102.000000)"
                    stroke="#000000"
                    stroke-width="2.2"
                  >
                    <g
                      id="curiosidade-aberto"
                      transform="translate(-0.500000, 0.000000)"
                    >
                      <g
                        id="&gt;"
                        transform="translate(864.669249, 103.282635)"
                      >
                        <path
                          d="M27.7445112,39.8078942 L51.6026094,51.6834976 C53.0465312,52.201197 54.3927021,50.7095422 53.7302804,49.3260483 L28.0804191,-4.24936817 C27.4179975,-5.63286213 25.4121978,-5.51962331 24.9097323,-4.07062349 L5.59921356,51.6149449 C5.09090839,53.0807021 6.63308903,54.4154475 8.01074332,53.7019922 L27.7445112,39.8078942 Z"
                          id="Fill-1"
                          transform="translate(29.701949, 24.337500) rotate(-270.000000) translate(-29.701949, -24.337500) "
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div class="about__footer">
          <div class="about__footer__left">
            <a
              href=""
              class="btn btn--small btn--purple"
              @click.prevent="scrollToSectionByName('faq')"
            >
              <span> perguntas e respostas </span>
            </a>
          </div>
          <div class="about__footer__right">
            <div>
              <p>
                Envie seu <br />
                projeto até
              </p>
              <h3>03dez</h3>
            </div>
            <a
              href="https://inscricoes.criativosdaescola.com.br/"
              target="_blank"
              class="btn btn--large btn--blue"
            >
              <span> inscreva-se </span>
            </a>
          </div>
        </div>
      </div>
      <div :class="['about-modal', { active: modal }]" @click="modal = false">
        <div class="about-modal__wrapper" @click.stop>
          <div class="about-modal__images">
            <img src="@/assets/img/close.svg" alt="" @click="modal = false" />
            <img src="@/assets/img/exclamation.svg" alt="" />
          </div>
          <div class="about-modal__content">
            <h2>Uma <span>curiosidade</span></h2>
            <p>
              Lançado em 2015 pelo Instituto Alana, o Criativos da Escola faz
              parte do Design for Change, um movimento global que surgiu na
              Índia e já mobilizou mais de
              <strong>2,2 milhões de crianças e adolescentes </strong>em
              <strong>70 países!</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="page participate" ref="participate">
      <div class="container">
        <TheTitle class="title--pink">Como participar</TheTitle>
        <div class="participate__content">
          <h2 class="title sticky">
            Veja abaixo as principais etapas para elaborar e inscrever seu
            projeto
          </h2>
          <ul class="participate__nav">
            <li
              :class="[{ disabled: currentParticipate == 0 }]"
              @click="goParticipate('up')"
            >
              <svg
                width="37px"
                height="44px"
                viewBox="0 0 37 44"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="up"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-1249.000000, -387.000000)"
                    fill="#FFFFFF"
                    stroke="#000000"
                    stroke-width="2.08"
                  >
                    <path
                      d="M1266.18309,419.848002 L1282.45561,428.248497 C1283.47461,428.613845 1284.42462,427.561163 1283.95714,426.584812 L1266.39885,388.756907 C1265.93137,387.780555 1264.51585,387.860469 1264.16125,388.883049 L1249.99036,428.200119 C1249.63164,429.234525 1250.71998,430.176473 1251.69221,429.672978 L1266.18309,419.848002 Z"
                      id="Fill-1"
                      transform="translate(1267.000000, 408.940552) scale(-1, 1) rotate(-358.000000) translate(-1267.000000, -408.940552) "
                    ></path>
                  </g>
                </g>
              </svg>
            </li>
            <li
              :class="[{ disabled: currentParticipate == 3 }]"
              @click="goParticipate('down')"
            >
              <svg
                width="37px"
                height="44px"
                viewBox="0 0 37 44"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="down"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-1249.000000, -387.000000)"
                    fill="#FFFFFF"
                    stroke="#000000"
                    stroke-width="2.08"
                  >
                    <path
                      d="M1266.18309,419.848002 L1282.45561,428.248497 C1283.47461,428.613845 1284.42462,427.561163 1283.95714,426.584812 L1266.39885,388.756907 C1265.93137,387.780555 1264.51585,387.860469 1264.16125,388.883049 L1249.99036,428.200119 C1249.63164,429.234525 1250.71998,430.176473 1251.69221,429.672978 L1266.18309,419.848002 Z"
                      id="Fill-1"
                      transform="translate(1267.000000, 408.940552) scale(-1, 1) rotate(-358.000000) translate(-1267.000000, -408.940552) "
                    ></path>
                  </g>
                </g>
              </svg>
            </li>
          </ul>

          <div class="participate__grid" ref="participateGrid">
            <section
              class="participate__item sticky"
              :ref="(el) => (participateItems[index] = el)"
              v-for="(card, index) in cards"
              :key="index"
            >
              <div class="participate__item__container">
                <div class="participate__item__wrapper">
                  <div class="description" v-html="card.descricao"></div>
                  <h2>{{ card.titulo }}</h2>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="participate__footer">
          <h3>
            Bom trabalho
            <span>
              <img src="@/assets/img/star.svg" alt="" />
              Boa sorte
            </span>
          </h3>
          <div class="participate__footer__buttons">
            <a
              href="/regulamento.pdf"
              target="_blank"
              class="btn btn--small btn--purple"
            >
              <span>regulamento </span>
            </a>
            <!-- <a href="" class="btn btn--small btn--purple">
              <span>material do educador </span>
            </a> -->
            <a
              href=""
              class="btn btn--small btn--purple"
              @click.prevent="scrollToSectionByName('faq')"
            >
              <span>perguntas e respostas </span>
            </a>
            <a
              href="https://inscricoes.criativosdaescola.com.br/"
              target="_blank"
              class="btn btn--small btn--pink hide-on-desktop"
            >
              <span>inscreva-se </span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="page instagram" ref="instagram">
      <div class="container">
        <TheTitle class="title--yellow">para inspirar</TheTitle>
        <div class="instagram__wrapper">
          <div class="instagram__content">
            <h2>
              Nossas redes estão cheias de dicas e inspirações para ajudar no
              desenvolvimento dos projetos. Dê uma olhada aqui embaixo e
              aproveite para seguir a gente.
            </h2>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/criativosdaescola/"
                  target="_blank"
                  class="btn btn--insta"
                >
                  <span>
                    <i class="icon-instagram"></i>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://pt-br.facebook.com/criativosdaescola/"
                  target="_blank"
                  class="btn btn--facebook"
                >
                  <span>
                    <i class="icon-facebook"></i>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://linktr.ee/premiocriativos"
                  target="_blank"
                  class="btn btn--linktree"
                >
                  <span>
                    <i class="icon-linktree"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <Carousel
            ref="carousel"
            :breakpoints="breakpoints"
            class="instagram__grid"
            v-if="instagram"
          >
            <Slide
              v-for="(slide, index) in instagram"
              :key="index"
              class="instagram__item"
            >
              <blockquote
                class="instagram-media"
                :data-instgrm-permalink="`https://www.instagram.com/p/${extractInstagramId(
                  slide.link
                )}/`"
                data-instgrm-version="14"
              ></blockquote>
            </Slide>
          </Carousel>
          <div class="custom-pagination">
            <button
              @click="prev"
              :class="['prev', { disabled: currentPage == 1 }]"
            >
              <img src="@/assets/img/arrow-prev.svg" alt="" />
            </button>
            <div class="custom-pagination__wrapper" v-if="totalPage > 1">
              <button v-for="page in parseInt(totalPage)" :key="page">
                <span
                  :class="{ active: page == currentPage }"
                  @click="goToPage(page)"
                ></span>
              </button>
            </div>
            <button
              @click="next"
              :class="['next', { disabled: currentPage == totalPage }]"
            >
              <img src="@/assets/img/arrow-next.svg" alt="" />
            </button>
          </div>
          <a
            href="https://inscricoes.criativosdaescola.com.br/"
            target="_blank"
            class="btn btn--small btn--pink hide-on-desktop"
          >
            <span>inscreva-se </span>
          </a>
        </div>
      </div>
    </section>
    <section class="page faq" ref="faq">
      <div class="container">
        <TheTitle class="title--yellow">perguntas e respostas</TheTitle>
        <div class="faq__wrapper">
          <h2>
            Para ajudar você a entender tudo direitinho, respondemos aqui as
            perguntas mais frequentes
          </h2>
          <div class="faq__grid">
            <faq-item
              :isActive="activeIndex === item.id"
              @toggle="toggleItem(item.id)"
              :item="item.id"
              :title="item.titulo"
              :content="item.descricao"
              v-for="item in faq"
              :key="item.id"
            />
          </div>

          <a
            href="https://inscricoes.criativosdaescola.com.br/"
            target="_blank"
            class="btn btn--small btn--pink hide-on-desktop"
          >
            <span>inscreva-se </span>
          </a>
        </div>
      </div>
    </section>
    <section class="page contact" ref="contact">
      <div class="container">
        <TheTitle class="title--yellow">fale com a gente</TheTitle>
        <div class="contact__wrapper">
          <h2>Ainda tem dúvidas? Mande sua mensagem!</h2>
          <form @submit.prevent="submitForm" class="form">
            <div class="form__wrapper" v-if="!successMessage">
              <div
                :class="[
                  'form__group form__group--textarea',
                  { 'form__group--error': errors.message },
                ]"
              >
                <label>Mensagem</label>
                <textarea v-model="form.message" required></textarea>
              </div>
              <div>
                <div
                  :class="[
                    'form__group',
                    { 'form__group--error': errors.name },
                  ]"
                >
                  <label>Nome</label>
                  <input type="text" v-model="form.name" required />
                </div>
                <div
                  :class="[
                    'form__group',
                    { 'form__group--error': errors.email },
                  ]"
                >
                  <label>Email</label>
                  <input type="email" v-model="form.email" required />
                </div>
              </div>
            </div>
            <div
              class="form__wrapper form__wrapper--sucess"
              v-if="successMessage"
            >
              <h3>Mensagem enviada com sucesso!</h3>
              <a href="#" class="btn" @click.stop="backForm">
                <span>voltar</span>
              </a>
            </div>
            <div class="form__wrapper">
              <div>
                <p>
                  Nosso e-mail direto:
                  <a
                    href="mailto:contato@criativosdaescola.com.br"
                    target="_blank"
                    >contato@criativosdaescola.com.br</a
                  >
                </p>
                <p>
                  Assessoria de imprensa:
                  <a href="mailto:alana@2pro.com.br" target="_blank"
                    >alana@2pro.com.br</a
                  >
                </p>
              </div>
              <button
                class="btn btn--purple"
                type="submit"
                v-if="!successMessage"
              >
                <span>enviar</span>
              </button>
            </div>
          </form>

          <div class="contact__footer">
            <div class="contact__footer__partners">
              <div class="partners">
                <p>iniciativa</p>
                <div class="partners__logos">
                  <img src="@/assets/img/logo-alana.svg" alt="" />
                </div>
              </div>
              <div class="partners">
                <p>realização</p>
                <div class="partners__logos">
                  <img src="@/assets/img/logo-cde.svg" alt="" />
                </div>
              </div>
              <div class="partners">
                <p>movimento global</p>
                <div class="partners__logos">
                  <img src="@/assets/img/logo-dfc.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="contact__footer__infos">
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/criativosdaescola/"
                    target="_blank"
                    class="btn"
                  >
                    <span><i class="icon-instagram"></i></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://pt-br.facebook.com/criativosdaescola/"
                    target="_blank"
                    class="btn"
                  >
                    <span><i class="icon-facebook"></i></span
                  ></a>
                </li>
                <li>
                  <a
                    href="https://linktr.ee/premiocriativos"
                    target="_blank"
                    class="btn"
                  >
                    <span>
                      <i class="icon-linktree"></i>
                    </span>
                  </a>
                </li>
              </ul>
              <figure><img src="@/assets/img/selo.jpg" alt="" /></figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { Vue3Lottie } from "vue3-lottie";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import FaqItem from "@/components/FaqItem.vue";
import TheTitle from "@/components/TheTitle.vue";
import animaJSON from "@/assets/animations/galhos.json";
import featureMJson from "@/assets/animations/feature-m.json";
import featureJson from "@/assets/animations/feature.json";
import exclamacaoJson from "@/assets/animations/exclamacao.json";
export default {
  props: {
    headerComponent: {
      type: Object,
      required: true,
    },
    scrollToSection: {
      type: String,
      default: null,
    },
  },
  components: {
    Vue3Lottie,
    Carousel,
    Slide,
    FaqItem,
    TheTitle,
  },
  watch: {
    scrollToSection(newVal) {
      if (newVal) {
        this.scrollToSectionByName(newVal);
      }
    },
    instagram(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.loadInstagramScript();
          this.updateBreakpoint();
        });
      }
    },
    cards(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.saveItemPositions();
        });
      }
    },
  },
  data() {
    return {
      cards: [],
      faq: [],
      instagram: [],
      form: {
        name: "",
        email: "",
        message: "",
      },
      errors: {
        name: null,
        email: null,
        message: null,
      },
      participateItems: [],
      successMessage: false,
      errorMessage: "",
      currentPage: 1,
      totalPage: 1,
      activeIndex: null,
      breakpoints: {
        0: {
          itemsToShow: 1,
          itemsToScroll: 1,
          navigationEnabled: false,
          paginationEnabled: false,
          touchDrag: true,
        },
        1024: {
          itemsToShow: 4,
          itemsToScroll: 4,
          navigationEnabled: false,
          paginationEnabled: false,
          mouseDrag: true,
        },
        1366: {
          itemsToShow: 5,
          itemsToScroll: 5,
          navigationEnabled: false,
          paginationEnabled: false,
          mouseDrag: true,
        },
        1440: {
          itemsToShow: 4,
          itemsToScroll: 4,
          navigationEnabled: false,
          paginationEnabled: false,
          mouseDrag: true,
        },
      },
      anima: {
        galhos: animaJSON,
        destaqueM: featureMJson,
        destaque: featureJson,
        exclamacao: exclamacaoJson,
      },
      modal: false,
      currentSectionIndex: 0,
      scrolling: false,
      lastScrollPosition: 0,
      observer: null,
      currentParticipate: 0,
      isScrolling: false,
      itemPositions: [],
    };
  },
  computed: {
    headerRef() {
      return this.headerComponent;
    },
  },
  methods: {
    validateName() {
      if (!this.form.name) {
        this.errors.name = "O nome é obrigatório.";
      } else {
        this.errors.name = null;
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.form.email) {
        this.errors.email = "O email é obrigatório.";
      } else if (!emailPattern.test(this.form.email)) {
        this.errors.email = "Digite um email válido.";
      } else {
        this.errors.email = null;
      }
    },
    validateMessage() {
      if (!this.form.message) {
        this.errors.message = "A mensagem é obrigatória.";
      } else {
        this.errors.message = null;
      }
    },
    backForm() {
      this.successMessage = false;
      this.errorMessage = "";
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
    },
    async submitForm() {
      this.validateName();
      this.validateEmail();
      this.validateMessage();

      if (!this.errors.name && !this.errors.email && !this.errors.message) {
        const params = new URLSearchParams(this.form).toString();
        try {
          const response = await fetch(
            `https://premio.criativosdaescola.com.br/wordpress/mail.php?${params}`,
            {
              method: "GET",
            }
          );

          const result = await response.json();

          if (result.success) {
            this.successMessage = result.message;
            this.errorMessage = "";
            this.form.name = "";
            this.form.email = "";
            this.form.message = "";
          } else {
            this.errorMessage = result.message;
            this.successMessage = "";
          }
        } catch (error) {
          this.errorMessage =
            "Houve um erro ao enviar a mensagem. Tente novamente mais tarde.";
          this.successMessage = "";
        }
      }
    },
    goToPage(page) {
      const width = window.innerWidth;
      const carousel = this.$refs.carousel;
      let total =
        width >= 1024
          ? this.breakpoints[1024].itemsToScroll
          : this.breakpoints[0].itemsToScroll;
      const index = (page - 1) * total;
      this.currentPage = page;
      carousel.slideTo(index);
    },
    updateBreakpoint() {
      const width = window.innerWidth;
      let total =
        width >= 1024
          ? this.breakpoints[1024].itemsToScroll
          : this.breakpoints[0].itemsToScroll;
      let num = this.instagram.length / total;
      this.totalPage = Math.ceil(num);
    },
    next() {
      if (this.currentPage < this.totalPage) {
        this.$refs.carousel.next();
        this.currentPage++;
      }
    },
    prev() {
      if (this.currentPage > 1) {
        this.$refs.carousel.prev();
        this.currentPage--;
      }
    },
    goParticipate(direction) {
      if (direction == "up" && this.currentParticipate > 0) {
        this.currentParticipate--;
        this.goToParticipate(this.currentParticipate);
      } else if (
        direction == "down" &&
        this.currentParticipate < this.itemPositions.length - 1
      ) {
        this.currentParticipate++;
        this.goToParticipate(this.currentParticipate);
      }
    },
    goToParticipate(index) {
      const offsetTop = this.itemPositions[index] + index * 20;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    },
    toggleItem(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    onScroll() {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      this.itemPositions.forEach((pos, index) => {
        const nextPos = this.itemPositions[index + 1] || Infinity;

        if (scrollPosition >= pos && scrollPosition < nextPos) {
          this.currentParticipate = index;
        }
      });
    },
    handleGlobalScroll() {
      const direction = this.getScrollDirection();
      const sections = this.getSections();
      if (
        direction === "down" &&
        this.currentSectionIndex < sections.length - 1
      ) {
        this.currentSectionIndex++;
      } else if (direction === "up" && this.currentSectionIndex > 0) {
        this.currentSectionIndex--;
      }
      this.scrollToSectionByIndex(this.currentSectionIndex);
    },
    handleParticipateScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const participateSection = this.$refs.participate;

      if (scrollContainer && participateSection) {
        if (scrollContainer.scrollTop > participateSection.offsetTop) {
          this.scrollToSectionByIndex(this.currentSectionIndex);
        } else {
          this.scrollToSectionByIndex(
            this.getSections().indexOf(participateSection)
          );
        }
      }
    },
    getScrollDirection() {
      const currentScrollPosition = window.scrollY;
      let direction = "down";
      if (this.lastScrollPosition !== undefined) {
        if (currentScrollPosition < this.lastScrollPosition) {
          direction = "up";
        }
      }
      this.lastScrollPosition = currentScrollPosition;
      return direction;
    },
    extractInstagramId(url) {
      const regex = /\/(p|reel|tv)\/([^/?#&]+)/;
      const match = url.match(regex);

      if (match && match[2]) {
        return match[2];
      }
      return null;
    },

    getSections() {
      return [
        this.$refs.hero,
        this.$refs.about,
        this.$refs.participate,
        this.$refs.instagram,
        this.$refs.faq,
        this.$refs.contact,
      ];
    },

    scrollToSectionByName(sectionName) {
      this.scrolling = true;
      const targetSection = this.getSections().find(
        (section) => section && section.classList.contains(sectionName)
      );

      if (targetSection) {
        const width = window.innerWidth;
        const offsetTop =
          width >= 1024
            ? targetSection.getBoundingClientRect().top + window.pageYOffset
            : targetSection.getBoundingClientRect().top +
              window.pageYOffset -
              66;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
        // targetSection.scrollIntoView({ behavior: "smooth" });
        if (width < 1024) {
          setTimeout(() => {
            this.headerComponent.$refs.header.classList.remove(
              "header--outview"
            );
            this.headerComponent.$refs.header.classList.add("header--inview");
            this.headerComponent.$refs.header.classList.add("header--fixed");
          }, 800);
        }
        this.currentSectionIndex = this.getSections().indexOf(targetSection);
      }

      setTimeout(() => {
        this.scrolling = false;
      }, 800);
    },

    scrollToSectionByIndex(index) {
      this.scrolling = true;
      const sections = this.getSections();
      if (sections[index]) {
        sections[index].scrollIntoView({ behavior: "smooth" });
      }
      setTimeout(() => {
        this.scrolling = false;
      }, 800);
    },
    updateBodyClass() {
      const currentClasses = document.body.className.split(" ");

      const hasAccessibilityClass = currentClasses.includes("accessibility");
      const hasHighContrastClass = currentClasses.includes("has-high-contrast");

      document.body.className =
        `section-${this.currentSectionIndex}` +
        (hasAccessibilityClass ? " accessibility" : "") +
        (hasHighContrastClass ? " has-high-contrast" : "");
    },
    observeSections() {
      const observerBodyClassOptions = {
        root: null,
        threshold: 0.25,
      };

      this.observerBodyClass = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionIndex = this.getSections().indexOf(entry.target);
            if (sectionIndex !== -1) {
              this.currentSectionIndex = sectionIndex;
              this.updateBodyClass();
            }
          }
        });
      }, observerBodyClassOptions);

      const sections = this.getSections();
      sections.forEach((section) => {
        this.observerBodyClass.observe(section);
      });
    },
    loadInstagramScript() {
      if (window.instgrm) {
        window.instgrm.Embeds.process();
      } else {
        const script = document.createElement("script");
        script.setAttribute("src", "https://www.instagram.com/embed.js");
        script.async = true;
        document.body.appendChild(script);
      }
    },
    saveItemPositions() {
      this.itemPositions = this.participateItems.map(
        (item) => item.offsetTop - 260
      );
    },
    async fetchHome() {
      try {
        const response = await axios.get(
          "https://premio.criativosdaescola.com.br/wordpress/wp-json/acf/v3/pages/10"
        );
        this.cards = response.data.acf.card_items;
        this.instagram = response.data.acf.insta_items;
        this.faq = response.data.acf.faq_items;
      } catch (error) {
        this.error = "Erro ao buscar usuários.";
        console.error(error);
      }
    },
  },
  mounted() {
    this.fetchHome();
    // this.lastScrollPosition = window.scrollY;
    const hash = this.$route.hash;
    if (hash) {
      this.scrollToSectionByName(hash.replace("#", ""));
    }
    window.addEventListener("resize", this.updateScale);
    window.addEventListener("scroll", this.onScroll);
    this.updateBreakpoint();
    this.$nextTick(() => {
      this.observeSections();
      this.updateBodyClass();
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped lang="scss">
.hero {
  padding-bottom: 62px;
  @include media(">=desktop") {
    padding-bottom: initial;
  }
  @include media(">=large", "<wxga") {
    padding-bottom: 62px;
  }
  &__wrapper {
    position: relative;
    @include media("<desktop") {
      margin: 0px auto;
      max-width: calc(100vw - 30px);
    }
  }
  &__legend {
    display: none;
    @include media(">=desktop") {
      display: initial;
      position: absolute;
      top: 50%;
      right: 60px;
      transform: translateY(-2vh) rotate(-90deg);
      white-space: nowrap;
      width: 0;
      height: 0;
      p {
        color: color(white);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.75em;
      }
      img {
        transform: rotate(90deg);
        animation: heroLegend 2s infinite;
      }
    }
  }
  &__image {
    margin-top: 174px;
    text-align: center;
    @include media(">=desktop") {
      margin-top: 10%;
    }
    @include media(">=large") {
      margin-top: 13%;
    }
    @include media(">=wxga") {
      margin-top: 10%;
    }
  }
  &__content {
    margin: 45px 0 0;
    @include media(">=desktop") {
      display: flex;
      gap: 7%;
      margin: 5% 0 0;
      align-items: flex-end;
    }
    @include media(">=large", "<wxga") {
      margin: 2% 0 0;
    }
    &__text {
      color: color(white);
      text-align: center;
      @include media(">=desktop") {
        text-align: initial;
      }
      img {
        margin: 0px auto 14px;
        animation: rotate 20s linear infinite;
      }
      p {
        font-size: 1.75em;
        @include media(">=desktop") {
          max-width: 90%;
          font-size: 1.375em;
        }
        span {
          @include media("<desktop") {
            display: block;
            font-family: font(black);
            font-size: 3.25rem;
            text-transform: uppercase;
            color: color(yellow);
          }
        }
      }
      &__half {
        margin-top: 31px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        @include media(">=desktop") {
          margin-top: 22px;
          flex-direction: initial;
          gap: initial;
        }
        .botao {
          flex: 1 0;
          a {
            flex: 1 0 345px;
            max-height: 48px;
            @include media(">=desktop") {
              flex: 1 0 292px;
              max-height: 60px;
            }
            @include media(">=wxga") {
              max-height: initial;
            }
            span {
              @include media("<desktop") {
                padding: 12px 0 4px;
              }
            }
          }
        }
        div {
          display: flex;
          align-items: center;
          font-family: font(black);
          gap: 17px;
          order: -1;
          @include media(">=desktop") {
            gap: 22px;
            order: initial;
          }
          p {
            font-size: 1.563em;
            line-height: 1.143em;
            text-transform: uppercase;
            text-align: right;
            margin-left: auto;
            color: color(white);
            max-width: 111px;
            @include media(">=desktop") {
              max-width: 40%;
              font-size: 1.313em;
            }
          }
          h3 {
            font-size: 3.625em;
            font-weight: 400;
            line-height: 0.8em;
            text-transform: uppercase;
            color: color(yellow);
            -webkit-text-stroke: 1.5px color(black);
            margin-right: auto;
            position: relative;
            @include media(">=desktop") {
              font-size: clamp(3.4375rem, 0.7232rem + 4.2411vw, 5.8125rem);
              margin-right: initial;
            }
            &::before {
              content: "";
              width: 2px;
              height: 100%;
              background: color(white);
              position: absolute;
              top: 0;
              left: -9px;
            }
          }
        }
      }
    }
    &__info {
      background-color: color(white);
      padding: 30px 30px;
      margin: 21px 10px 0;
      @include media(">=desktop") {
        padding: 26px 40px 28px;
        max-width: 41vw;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 36px;
        margin: initial;
      }
      @include media(">=hd") {
        padding: 26px 40px 28px 60px;
      }

      &__left {
        @include media(">=desktop") {
          flex: 1 0 40%;
        }
        p {
          font-size: 1.625em;
          line-height: 1em;
          font-family: font(black);
          text-transform: uppercase;
          display: flex;
          gap: 7px;
          align-items: center;
          @include media(">=desktop") {
            max-width: 90%;
            display: initial;
            font-size: 1.188em;
            position: relative;
          }
          div {
            max-width: 58px;
            @include media(">=desktop") {
              max-width: initial;
              width: auto;
              height: 100%;
              position: absolute;
              left: -40px;
              top: 0;
              transform: translateX(-50%);
              svg {
                width: initial !important;
                height: initial !important;
              }
            }
            @include media(">=hd") {
              left: -60px;
            }
          }
        }
        h3 {
          font-size: 3.875em;
          line-height: 1em;
          color: color(red);
          font-family: font(black);
          text-transform: uppercase;
          -webkit-text-stroke: 1.5px color(black);
          font-weight: 400;
          margin: 6px 0;
          @include media(">=desktop") {
            font-size: 2.025em;
            margin: initial;
          }
        }
      }
      &__right {
        font-size: 1.563em;
        line-height: 1em;
        text-transform: uppercase;
        @include media(">=desktop") {
          font-size: 1.025em;
        }
      }
    }
  }
}
.about {
  position: relative;
  overflow: hidden;
  padding-bottom: 58px;
  @include media(">=desktop") {
    padding-bottom: initial;
  }
  &-modal {
    background-color: RGBA(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
    overflow: hidden;
    @include media(">=desktop") {
      position: absolute;
    }
    &.active {
      transform: translateX(0%);
    }
    &__wrapper {
      width: 100%;
      background-color: color(yellow);
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0 20px 60px;
      position: relative;
      @include media(">=desktop") {
        max-width: 42vw;
        padding: 0 54px;
        margin-left: auto;
        justify-content: center;
        height: 100%;
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("@/assets/img/bg-linhas.svg");
        background-repeat: repeat;
        background-position: top center;
        background-size: 66px;
        z-index: 1;
        opacity: 0.15;
      }
    }
    &__images,
    &__content {
      position: relative;
      z-index: 2;
    }
    &__images {
      text-align: right;
      padding-top: 115px;
      @include media(">=desktop") {
        padding-top: initial;
        position: initial;
      }
      img {
        &:first-child {
          position: absolute;
          top: 75px;
          left: 0px;
          transition: 0.3s ease-in-out;
          @include media(">=desktop") {
            top: 103px;
            left: 54px;
            cursor: pointer;
            &:hover,
            &:focus {
              transform: rotate(-180deg);
            }
          }
        }
        &:last-child {
          @include media("<desktop") {
            max-height: 111px;
            transform: translateY(-30px);
          }
        }
      }
    }
    &__content {
      margin-top: -40px;
      @include media(">=desktop") {
        margin-top: initial;
      }
      h2 {
        font-size: 2.5em;
        line-height: 1em;
        font-weight: 900;
        font-family: font(fraunces);
        margin-bottom: 8px;
        text-transform: uppercase;
        @include media(">=desktop") {
          font-size: 2.25em;
          margin-bottom: 10px;
        }
        span {
          display: block;
        }
      }
      p {
        font-size: 1.563em;
        @include media(">=desktop") {
          font-size: 1.5em;
        }
        @include media(">=large", "<wxga") {
          font-size: 1.25em;
        }
        strong {
          color: color(red2);
        }
      }
    }
  }
  &__wrapper {
    margin: 0 auto;
    @include media(">=desktop") {
      display: flex;
      align-items: center;
      gap: 7%;
      margin: 15vh 0 0;
    }

    @include media(">=large", "<wxga") {
      margin: initial;
      padding-top: 140px;
    }
  }
  &__left {
    margin-bottom: 18px;
    @include media(">=desktop") {
      margin-bottom: initial;
      flex: 1 0 60%;
    }
    @include media(">=large", "<wxga") {
      flex: 1 0 67%;
    }
    &__wrapper {
      border: 2px solid color(black);
      background-color: color(red);
      color: color(white);
      padding: 27px 20px;
      @include media(">=desktop") {
        max-width: 100%;
        padding: 25px 42px;
      }
      h2 {
        font-size: 1.438em;
        line-height: 1.2em;
        text-transform: uppercase;
        @include media(">=desktop") {
          font-size: 1.5em;
        }
        @include media(">=large", "<wxga") {
          font-size: 1.125em;
        }
      }
      p {
        margin-top: 14px;
        font-size: 1.438em;
        @include media(">=desktop") {
          margin-top: 23px;
          font-size: 1.338em;
        }
        @include media(">=large", "<wxga") {
          font-size: 1em;
        }
      }
      div {
        display: flex;
        align-items: center;
        gap: 17px;
        margin-top: 48px;
        @include media(">=desktop") {
          gap: 28px;
          margin-top: 25px;
        }
        .galhos {
          max-width: 85px;
          margin: 0px;
          display: block;
          flex: 1 0 85px;
          margin-left: -42px;
          @include media(">=desktop") {
            max-width: 103px;
            flex: initial;
            margin: 0px;
          }
        }
        h3 {
          color: color(yellow);
          font-size: 1.75em;
          line-height: 1em;
          text-transform: uppercase;
          flex: 1 0 238px;
          @include media(">=desktop") {
            font-weight: 400;
            font-size: 1.875em;
            flex: initial;
          }

          @include media(">=large", "<wxga") {
            font-size: 1.5em;
          }
        }
      }
    }
  }
  &__right {
    text-align: center;
    @include media(">=desktop") {
      text-align: initial;
      width: 33%;
    }
    &__content {
      position: relative;
      @include media(">=hd") {
        transform: scale(1.1) translate(25%, -10%);
      }
      @include media(">=fhd") {
        transform: scale(1.5) translate(25%, -10%);
      }
      figure {
        position: relative;
        cursor: pointer;
        img {
          transition: 0.3s ease-in-out;
          &:first-child {
            width: 281px;
          }
          &:last-child {
            position: absolute;
            top: 10px;
            right: 25px;
            z-index: -1;
            @include media(">=desktop") {
              top: 8px;
              left: 8px;
              right: initial;
            }
          }
        }
      }
      .arrow {
        position: absolute;
        bottom: 18px;
        right: 60px;
        transition: 0.3s ease-in-out;
        animation: toLeft 2s infinite;
        @include media(">=desktop") {
          left: 190px;
          right: initial;
        }
        #about-arrow {
          fill: color(red);
          transition: 0.3s ease-in-out;
        }
      }

      &:hover,
      &:focus {
        @include media(">=desktop") {
          figure {
            img {
              &:first-child {
                transform: translate(8px, 8px);
              }
            }
          }
          .arrow {
            #about-arrow {
              fill: color(blue);
            }
          }
        }
      }
    }
  }
  &__footer {
    margin-top: 45px;
    @include media(">=desktop") {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: -70px;
    }
    @include media(">=large", "<wxga") {
      margin-top: -50px;
    }
    &__left {
      text-align: center;
      @include media(">=desktop") {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 28px;
      }
      p {
        font-size: 1.438em;
        font-weight: 600;
        margin-bottom: 14px;
        @include media(">=desktop") {
          font-size: 1.438em;
          font-weight: 500;
          margin-bottom: initial;
        }
      }
      a {
        // max-width: 251px;
        margin: 0px auto;
        @include media(">=desktop") {
          max-width: 435px;
          margin: initial;
        }
        @include media(">=hd") {
          max-width: 42%;
          span {
            white-space: nowrap;
          }
        }
      }
    }
    &__right {
      margin-top: 62px;
      @include media(">=desktop") {
        width: 510px;
        margin-top: initial;
      }
      @include media(">=hd") {
        width: 31%;
      }
      div {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 22px;
        margin-bottom: 16px;
        p {
          font-family: font(black);
          font-size: 1.75em;
          line-height: 1.143em;
          text-transform: uppercase;
          text-align: right;
          @include media(">=desktop") {
            font-size: 1.313em;
          }
        }
        h3 {
          font-family: font(black);
          color: color(yellow);
          font-size: 4.75em;
          line-height: 0.8em;
          font-weight: 400;
          text-transform: uppercase;
          -webkit-text-stroke: 1.5px color(black);
          position: relative;
          @include media(">=desktop") {
            font-size: 3.438em;
          }
          &::before {
            content: "";
            width: 2px;
            height: 100%;
            background: color(black);
            position: absolute;
            top: 0;
            left: -9px;
          }
        }
      }
      a {
        width: 100%;
        max-width: 365px;
        @include media(">=hd") {
          max-width: 100%;
        }
      }
    }
  }
}
.participate {
  counter-reset: section;
  align-items: initial;
  position: initial;
  background-image: url("@/assets/img/bg-linhas-opacity.svg");
  background-repeat: repeat;
  background-position: top center;
  background-size: 66px;
  background-attachment: fixed;
  .container {
    position: initial !important;
  }
  &__content {
    @include media(">=desktop") {
      padding-top: 172px;
    }
    .title {
      position: sticky;
      top: 50px;
      font-size: 1.438em;
      text-transform: uppercase;
      @include media(">=desktop") {
        top: 190px;
        font-size: 1.5em;
        margin-top: initial;
      }
    }
  }
  &__nav {
    display: none;
    @include media(">=desktop") {
      display: block;
      position: sticky;
      top: 50vh;
      right: 115px;
      transform: translateY(-50%);
      list-style: none;
      width: 37px;
      margin-left: auto;
      z-index: 3;
    }
    li {
      cursor: pointer;
      svg {
        path {
          transition: 0.3s ease-in-out;
        }
      }
      &.disabled {
        cursor: not-allowed;
        svg {
          opacity: 0.5;
        }
      }
      &:not(.disabled):hover {
        svg {
          path {
            fill: color(pink);
          }
        }
      }
      &:last-child {
        transform: scale(-1);
      }
    }
  }
  &__grid {
    margin-top: 30px;
    @include media(">=desktop") {
      margin-top: -76px;
      padding-right: 174px;
    }
  }
  &__item {
    position: sticky;
    top: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: margin-top 0.3s ease-out, transform 0.3s ease-out;
    transform: translateY(0%);
    transform-origin: top;
    @include media(">=desktop") {
      top: 260px;
    }
    &:not(:first-child) {
      margin-top: 20vh;
      @include media(">=wxga") {
        margin-top: 18vh;
      }
    }
    &.active {
      margin-top: -386px;
    }
    &:nth-child(2) {
      transform: translateY(10px);
    }
    &:nth-child(3) {
      transform: translateY(30px);
    }
    &:nth-child(4) {
      transform: translateY(60px);
    }
    &__container {
      background-color: color(yellow);
      border: 2px solid color(black);
      height: 436px;
      width: 100%;
      max-width: 1176px;
      margin: 0 auto;
      counter-increment: section;
      position: relative;
      padding: 109px 20px 20px;
      @include media(">=desktop") {
        height: 386px;
        max-width: initial;
        padding: 34px 27px 21px;
      }
      @include media(">=large", "<wxga") {
        max-height: 280px;
      }
      @include media(">=wxga") {
        // max-width: 89vw;
        height: 50vh;
      }
      &::after {
        content: counters(section, ".", decimal-leading-zero);
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 5em;
        line-height: 1em;
        color: color(pink);
        font-family: font(black);
        -webkit-text-stroke: 2px color(black);
        @include media(">=desktop") {
          font-size: 5.688em;
          right: 40px;
        }
      }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: -1;
        background-color: color(black);
      }
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      @include media(">=desktop") {
        max-width: 75%;
      }
    }
    .description {
      font-size: 1.313em;
      font-weight: 500;
      @include media(">=desktop") {
        font-size: 1.438em;
      }

      @include media(">=large", "<wxga") {
        font-size: 1.25em;
      }
    }
    h2 {
      font-family: font(black);
      font-weight: 400;
      font-size: 2.688em;
      text-transform: uppercase;
      @include media(">=desktop") {
        font-size: 2.875em;
      }
      @include media(">=large", "<wxga") {
        font-size: 2.375em;
      }
      @include media(">=wxga") {
        white-space: nowrap;
      }
    }
  }
  &__footer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 80px;
    padding-bottom: 8vh;
    @include media(">=desktop") {
      grid-template-columns: repeat(2, 1fr);
      padding-bottom: 40px;
    }
    h3 {
      display: flex;
      align-items: center;
      gap: 0 13px;
      font-size: 3.125em;
      text-transform: uppercase;
      font-weight: 400;
      font-family: font(black);
      color: color(purple);
      -webkit-text-stroke: 1.5px color(black);
      flex-wrap: wrap;
      justify-content: center;
      line-height: 0.8em;
      margin: 20px 0;
      @include media(">=desktop") {
        flex-wrap: initial;
        gap: 13px;
        justify-content: initial;
        font-size: 2.25em;
        line-height: initial;
        margin: initial;
      }
      span {
        @include media("<desktop") {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }
    &__buttons {
      margin-top: 20px;
      @include media(">=desktop") {
        display: flex;
        align-items: center;
        gap: 46px;
      }
      a {
        &:nth-child(2) {
          span {
            @include media(">=desktop") {
              padding-left: 30px;
              padding-right: 30px;
              white-space: nowrap;
            }
          }
        }
        span {
          @include media(">=desktop") {
            font-size: 1.25em;
          }
        }
        @include media("<desktop") {
          &:nth-child(1) {
            max-width: 245px;
            margin: 0px auto 19px;
          }
          &:nth-child(2) {
            max-width: 345px;
            margin: 0px auto 29px;
          }
          &:nth-child(3) {
            max-width: 345px;
            margin: 0px auto;
          }
        }
        span {
          @include media("<desktop") {
            font-size: 1.75em;
          }
        }
      }
    }
  }
}
.instagram {
  padding: 0 0 55px;
  @include media("<desktop") {
    overflow: hidden;
  }
  @include media(">=desktop") {
    padding: initial;
  }
  @include media(">=large", "<wxga") {
    min-height: initial;
    padding: 0 0 20px;
  }
  &__wrapper {
    @include media(">=desktop") {
      padding-right: 40px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 19px;
    @include media(">=desktop") {
      align-items: flex-end;
      flex-direction: initial;
      padding-top: 156px;
      gap: initial;
    }
    @include media(">=large", "<wxga") {
      padding-top: 120px;
      gap: 10px;
    }
    @include media(">=large", "<wxga") {
      padding-top: 140px;
    }
    @include media(">=ex-large") {
      padding-top: 15vh;
    }
    h2 {
      color: color(white);
      font-weight: 700;
      line-height: 1.2em;
      text-transform: uppercase;
      font-size: 1.438em;
      @include media(">=desktop") {
        max-width: 66vw;
        font-size: 1.5em;
      }
      @include media(">=large", "<wxga") {
        max-width: 72vw;
        font-size: 1.033em;
      }
    }
    ul {
      list-style: none;
      display: inline-flex;
      gap: 20px;
      @include media(">=desktop") {
        margin-left: auto;
        margin-right: 57px;
      }
      a {
        span {
          width: 60px;
          height: 60px;
          @include media(">=desktop") {
            padding: initial;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: initial !important;
            i {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
  &__grid {
    margin: 26px auto 0;
    width: 346px;
    @include media(">=desktop") {
      width: calc(100% + 20px);
      margin: 26px 0 0 -10px;
    }
  }
  &__item {
    max-width: 100%;
  }
  .custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin-top: 27px;
    @include media("<desktop") {
      justify-content: space-between;
    }
    button {
      appearance: none;
      border: 0px none;
      padding: 0px;
      background: initial;
      cursor: pointer;
      &.next,
      &.prev {
        &.disabled {
          opacity: 0.5;
        }
      }
    }
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      @include media("<desktop") {
        order: 3;
        margin-left: auto;
      }
      span {
        width: 19px;
        height: 19px;
        border: 2px solid color(black);
        border-radius: 50%;
        background: color(green);
        transition: 0.3s ease-in-out;
        display: block;
        &.active {
          background: color(blue);
        }
      }
    }
  }
  .btn {
    margin-top: 10px;
    @include media(">=desktop") {
      margin-top: 42px;
    }
    span {
      font-size: 1.75em;
      padding: 15px 0px 7px;
    }
  }
}
.faq {
  align-items: initial;
  padding: 0 0 55px;
  @include media(">=desktop") {
    padding: initial;
  }
  &__wrapper {
    @include media(">=desktop") {
      padding-top: 172px;
      padding-right: 40px;
      padding-bottom: 126px;
    }

    h2 {
      font-size: 1.438em;
      line-height: 1.2em;
      text-transform: uppercase;
      color: color(white);
      @include media(">=desktop") {
        font-size: 1.5em;
        max-width: 60vw;
      }
    }
  }
  &__grid {
    margin-top: 25px;
    @include media(">=desktop") {
      margin-top: 41px;
    }
  }

  .btn {
    margin-top: 40px;
    span {
      font-size: 1.75em;
    }
  }
}
.contact {
  align-items: initial;
  padding: 0 0 55px;
  @include media("<desktop") {
    overflow: hidden;
  }
  @include media(">=desktop") {
    padding: initial;
  }
  &__wrapper {
    @include media(">=desktop") {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      justify-content: flex-end;
      height: 100%;
      padding-top: 166px;
    }
    h2 {
      font-size: 1.438em;
      line-height: 1.2em;
      text-transform: uppercase;
      color: color(white);
      @include media(">=desktop") {
        font-size: 1.5em;
        max-width: 60vw;
      }
    }
  }
  .form {
    margin-top: 13px;
    @include media(">=desktop") {
      margin-top: 45px;
      padding-right: 30px;
    }
    @include media(">=large", "<wxga") {
      margin-top: 5px;
    }
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      @include media(">=desktop") {
        grid-template-columns: repeat(2, 1fr);
        gap: 37px;
      }
      &:nth-child(1) {
        div:last-child {
          order: -1;
          @include media(">=desktop") {
            order: initial;
          }
        }
      }
      &:nth-child(2) {
        align-items: center;
        div {
          order: 2;
          margin-top: 34px;
          @include media(">=desktop") {
            order: initial;
            margin-top: initial;
          }
        }
        p {
          color: color(white);
          font-size: 1.438em;
          line-height: 1.5em;
          text-align: center;
          @include media(">=desktop") {
            text-align: initial;

            font-size: 1.125em;
            line-height: 1.5em;
          }
          &:not(:last-child) {
            @include media("<desktop") {
              margin-bottom: 30px;
            }
          }
          a {
            color: color(blue);
            font-weight: 700;
            text-decoration: underline;
            display: block;
            margin-top: initial;
            @include media(">=desktop") {
              display: initial;
            }
          }
        }
      }

      &--sucess {
        text-align: center;
        display: block;
        position: relative;
        z-index: 2;
        height: 30vh;
        h3 {
          margin-bottom: 21px;
          font-size: 1.438em;
          line-height: 1.2em;
          text-transform: uppercase;
          color: color(yellow);
          @include media(">=desktop") {
            font-size: 1.5em;
          }
        }
        a {
          max-width: 300px;
          margin: 0px auto;
          &::before,
          &::after {
            background: color(yellow);
          }
        }
      }
    }
    &__group {
      &--textarea {
        @include media("<desktop") {
          margin-bottom: initial;
        }
      }
      label {
        color: color(yellow);
        display: block;
        font-size: 1.5em;
        margin-bottom: 4px;
        text-transform: uppercase;
        font-family: font(black);
        transition: 0.3s ease-in-out;
        @include media(">=desktop") {
          font-size: 1.313em;
          margin-bottom: 7px;
        }
        @include media(">=large", "<wxga") {
          font-size: 1.125em;
        }
      }
      textarea {
        height: 203px;
        @include media(">=large", "<wxga") {
          height: 168px;
        }
      }
      input,
      textarea {
        width: 100%;
        padding: 9px 18px 11px;
        background-color: color(white);
        appearance: none;
        border: 0px none;
        font-size: 2em;
        line-height: 1.5em;
        @include media(">=desktop") {
          font-size: 1.063em;
        }
      }
      &:not(:last-child) {
        margin-bottom: 19px;
      }
      &.form__group--error {
        label {
          color: color(red);
        }
      }
    }
    button {
      margin-top: 19px;
      appearance: none;
      border: 0px none;
      background: initial;
      font-size: initial;
      @include media(">=desktop") {
        width: 290px;
      }
      @include media(">=large", "<wxga") {
        span {
          font-size: 1.125em;
        }
      }
      &::after,
      &::before {
        background-color: color(yellow);
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 92px;
    @include media(">=desktop") {
      padding-top: 10vh;
      flex-wrap: initial;
    }
    &__partners,
    &__infos {
      display: flex;
    }
    &__partners {
      gap: 28px;
      font-size: 1.125em;
      color: color(white);
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      @include media(">=desktop") {
        flex-wrap: initial;
        justify-content: initial;
      }
      .partners {
        display: flex;
        flex-direction: column;
        gap: 9px;
        flex: 1 0 45%;
        align-items: center;
        @include media(">=desktop") {
          gap: 24px;
          flex-direction: initial;
          flex: initial;
          align-items: initial;
        }
        &:last-child {
          @include media("<desktop") {
            flex: 1 0 100%;
          }
        }
        p {
          @include media(">=desktop") {
            max-width: 90px;
            line-height: 1em;
            text-align: right;
            font-size: 0.7em;
          }
          @include media(">=large", "<wxga") {
            font-size: 0.8em;
          }
          @include media(">=hd") {
            font-size: 0.5em;
          }
        }
        &__logos {
          flex: 1 0 100%;
        }
      }
    }
    &__infos {
      gap: 30px;
      width: 100%;
      justify-content: center;
      margin-top: 48px;
      @include media(">=desktop") {
        gap: 55px;
        justify-content: flex-end;
        margin-top: initial;
      }
      ul {
        display: flex;
        gap: 10px;
        list-style: none;
        a {
          display: block;
          span {
            width: 49px;
            height: 49px;
            background-color: color(white);
            display: flex;
            align-items: center;
            justify-content: center;
            color: color(black);
            padding: 0;
            i {
              font-size: 27px;
            }
          }
          &::after,
          &::before {
            background: color(yellow);
          }
          &::before {
            width: 5px;
            height: calc(100% - 3px);
            right: -5px;
          }
          &::after {
            width: calc(100% - 2px);
            height: 5px;
            bottom: -5px;
          }
          @include media(">=desktop") {
            &:hover,
            &:focus {
              span {
                transform: translateX(5px) translateY(5px);
              }
              &::before {
                height: calc(100% - 13px);
              }
              &::after {
                width: calc(100% - 13px);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toLeft {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px);
  }
}
@keyframes heroLegend {
  0%,
  100% {
    transform: rotate(90deg) translateY(0);
  }
  50% {
    transform: rotate(90deg) translateY(10px);
  }
}
</style>
